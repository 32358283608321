<template>
  <div class="table-coin-book-list">
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        :rowClassName='rowClassName'
        @change="changePage"
    >
<!--   版别列表   -->
      <div slot="itemVersionListSlot" slot-scope="row">
        <a-tag
            color="blue"
            class="tag-class"
            v-for="item in row.coinItemVersionList"
            :key="item.id"
        >{{ item.itemName + '-' + item.versionName }}</a-tag>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
            class="ml-20" v-if="row.pid==0"
            type="primary"
            style="margin-bottom: 5px"
            @click="handleEditorClassifyVersion(row)"
        >编辑一级分类</a-button>


        <a-button
            class="ml-20" 
            type="primary" v-if="row.pid!=0"
            style="margin-bottom: 5px"
            @click="handleEditorClassifyTwoVersion(row)"
        >编辑二级分类</a-button>

        <a-popconfirm
            title="是否确认删除?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteClassifyVersion(row)"
        >
          <a-button class="ml-20" type="danger">删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
     <!-- 添加或编辑一级图库  -->
    <AddOrEditorPopup ref="addOrEditorPopupEl" @success="handleSuccess"/>

      <!-- 添加或编辑二级图库  -->
      <AddOrEditorPopupTwo ref="addOrEditorPopupTwoEl" @success="handleSuccess"/>
  </div>
</template>
<script>
import AddOrEditorPopup from "@/views/cmsPage/coinBookManage/classifyVersion/_components/addOrEditorPopup/index.vue"
import AddOrEditorPopupTwo from "@/views/cmsPage/coinBookManage/classifyVersion/_components/addOrEditorPopupTwo/index.vue"
import {coinBookClassifyVersionListTableColumns} from "@/views/cmsPage/coinBookManage/classifyVersion/_data"
import {deleteClassifyVersionByCoinBook} from "@/views/cmsPage/coinBookManage/classifyVersion/_apis"

export default {
  props: ['list', 'pagination'],
  components: {AddOrEditorPopup,AddOrEditorPopupTwo},
  data() {
    return {
      tableList: coinBookClassifyVersionListTableColumns,
    }
  },
  computed: {
  },
  methods: {
    /** 修改二级分类的背景颜色 */
    rowClassName(row) {
      return row.pid > 0 ? 'color-green' : ''
    },
    /** 编辑一级分类 */
    handleEditorClassifyVersion(row) {
       console.log(row)
      this.$refs.addOrEditorPopupEl.show(row)
    },
      /** 编辑二级分类 */
      handleEditorClassifyTwoVersion(row) {
       console.log(row)
      this.$refs.addOrEditorPopupTwoEl.show(row)
    },
    /** 删除 */
    async handleDeleteClassifyVersion(row) {
      const res = await deleteClassifyVersionByCoinBook({ id: row.id })
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
.tag-class {
  margin-bottom: 5px;
}
/* 移除Table行鼠标hover时的背景色 */
.table-coin-book-list::v-deep .ant-table-tbody>tr.ant-table-row:hover>td {
  background: none !important;
}
</style>