import instance from "@/axios"

/** 活谱-分类列表管理 */
export const getClassifyList = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionClassfy/list',
        method: 'GET',
        params: params,
    })
}

/** 删除-分类管理数据 */
export const deleteClassifyVersionByCoinBook = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionClassfy/delById',
        method: 'GET',
        params: params
    })
}

/** 搜索版别内容-分类管理数据 */
export const getVersionListApi = async (params) => {
    return instance({
        url: '/dq_admin/kinditemversion/list2',
        method: 'GET',
        params: params
    })
}

/** 编辑，添加-分类管理数据 */
export const addOrEditorClassifyVersionByCoinBook = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionClassfy/addOrUpdate',
        method: 'POST',
        data: params
    })
}

/** 编辑，添加-二级分类管理数据 */
export const addOrEditorTwoClassifyVersionByCoinBook = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionClassfy/addOrUpdateTwo',
        method: 'POST',
        data: params
    })
}


export const getOneClassfyList = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionClassfy/listClassfyQuery',
        method: 'GET',
        params: params
    })
}

/** 搜索二级分类列表 */
export const getTwoClassfyList = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionClassfy/listClassfyQuery',
        method: 'GET',
        params: params
    })
}

/** 搜索小币种ID */
export const getSmallCoinListByBigCoin = async (params) => {
    return instance({
        url: '/dq_admin/kinditem/list',
        method: 'GET',
        params: {
            pageNum: 1,
            pageSize: 100,
            coinId: params.coinId,
            coinItemName: params.coinItemName,
        }
    })
}