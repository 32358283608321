var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-coin-book-list" },
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          pagination: _vm.pagination,
          rowKey: function(record, index) {
            return index
          },
          rowClassName: _vm.rowClassName
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemVersionListSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.coinItemVersionList, function(item) {
                  return _c(
                    "a-tag",
                    {
                      key: item.id,
                      staticClass: "tag-class",
                      attrs: { color: "blue" }
                    },
                    [_vm._v(_vm._s(item.itemName + "-" + item.versionName))]
                  )
                }),
                1
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.pid == 0
                    ? _c(
                        "a-button",
                        {
                          staticClass: "ml-20",
                          staticStyle: { "margin-bottom": "5px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleEditorClassifyVersion(row)
                            }
                          }
                        },
                        [_vm._v("编辑一级分类")]
                      )
                    : _vm._e(),
                  row.pid != 0
                    ? _c(
                        "a-button",
                        {
                          staticClass: "ml-20",
                          staticStyle: { "margin-bottom": "5px" },
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.handleEditorClassifyTwoVersion(row)
                            }
                          }
                        },
                        [_vm._v("编辑二级分类")]
                      )
                    : _vm._e(),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "是否确认删除?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleDeleteClassifyVersion(row)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { staticClass: "ml-20", attrs: { type: "danger" } },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("AddOrEditorPopup", {
        ref: "addOrEditorPopupEl",
        on: { success: _vm.handleSuccess }
      }),
      _c("AddOrEditorPopupTwo", {
        ref: "addOrEditorPopupTwoEl",
        on: { success: _vm.handleSuccess }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }