/** 活谱-分类管理-table */
export const coinBookClassifyVersionListTableColumns = [
    { title: "ID", align: "center", dataIndex: 'id' },
    { title: "分类名称", align: "center", dataIndex: 'classfyName', width: 100 },
    { title: "大币种", align: "center", dataIndex: 'coinName', width: 100 },
    { title: "版别列表", align: "left", scopedSlots: { customRender: 'itemVersionListSlot' }, width: 600 },
    { title: "分类描述", align: "center", dataIndex: 'classfyDesc', width: 300 },
    { title: "分类排序", align: "center", dataIndex: 'classfySort' },
    { title: "分类数量", align: "center", dataIndex: 'classfySize' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]